<template>
  <div class="service-info p-col-6">
    <div class="form-input p-mt-3">
      <p>{{ i18n.$t('Choose a service') }}</p>
      <input
        type="hidden"
        name="sub_category_ids"
        v-model="items.category_id"
      />
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
          <TreeSelect
            name="sub_category_ids"
            v-model="category_id"
            :options="childrenCategories"
            @change="onChangeTreeCategory($event)"
            selectionMode="single"
            :metaKeySelection="false"
            placeholder="Select Category"
          >
            <template #header="{ value }">
              <div class="p-col-12">
                <span
                  class="p-float-label p-input-icon-right"
                  style="width: 100%"
                >
                  <i class="pi pi-search" />
                  <InputText
                    class="p-col-12"
                    id="inputtext-right"
                    type="text"
                    v-model="filterCategory"
                    @keyup="doFilterCategory"
                  />
                </span>
              </div>
            </template>
          </TreeSelect>
        </div>
        <div class="p-col-12 p-md-6">
          <Dropdown
            :options="lastlevelCategories"
            v-model="selected_category"
            @change="onChangeCategory($event)"
            optionLabel="name"
            :filter="true"
            placeholder="Select SubCategory"
            :showClear="true"
            :loading="loadingCategories"
          />
        </div>
      </div>
    </div>

    <div>
      <Button
        @click="showOther = !showOther"
        :label="`${showOther ? 'Hide' : 'Display'} Other`"
        class="p-button-link"
      />
    </div>

    <div v-if="showOther">
      <div class="flex p-mt-3 items-center">
        <p class="w-1/5">{{ i18n.$t('Name') }}</p>
        <InputText
          type="text"
          v-model="items.name"
          class="border border-gray-500 rounded p-1 px-3"
          required
        />
      </div>
      <div class="flex mt-5 p-mt-3">
        <p class="">{{ i18n.$t('Type') }}</p>
        <Dropdown
          v-model="items.type"
          :options="providerTypes"
          optionLabel="name"
          optionValue="value"
          placeholder="Select a type"
        />
      </div>
    </div>
    <div class="p-mt-3">
      <p class="">{{ i18n.$t('Number of reservation units') }}</p>
      <Dropdown
        v-model="items.count_booking_per_user"
        :options="bookingUserUnits"
        optionLabel="name"
        optionValue="value"
        placeholder="Select a type"
      />
    </div>
    <div class="form-input p-mt-3">
      <p>{{ i18n.$t('Select Employees for services') }}</p>
      <input type="hidden" name="employee_id" v-model="items.employee_id" />
      <MultiSelect
        name="employee"
        v-model="items.employee_id"
        :options="employees"
        optionLabel="first_name"
        optionValue="id"
      />
    </div>

    <div class="flex p-mt-3 items-center">
      <p class="w-1/5">{{ i18n.$t('Price') }}</p>
      <InputNumber
        v-model="items.price"
        mode="currency"
        :currency="items.currency"
        locale="de-DE"
      />
    </div>
    <div class="form-input p-mt-3" v-if="categoryCode != null">
      <p>{{ i18n.$t('motifs') }}</p>
      <AutoComplete
        :multiple="true"
        v-model="motifs_selected"
        :suggestions="motifs"
        @complete="filterMotifs($event)"
        @item-select="changeMotiffs"
        @item-unselect="changeMotiffs"
        @clear="changeMotiffs"
        field="value"
        delay="500"
      />
    </div>
    <div class="flex p-mt-3 items-center">
      <p class="">{{ i18n.$t('Duration') }}</p>
      <div class="duration p-mt-2">
        <div class="p-mr-3">
          <label for="">Hours:</label>
          <Dropdown
            v-model="hours"
            :options="hoursList"
            optionLabel="name"
            optionValue="value"
            @change="changeDuration"
          />
        </div>
        <div>
          <label for="">Minutes:</label>
          <Dropdown
            v-model="minutes"
            :options="minutesList"
            optionLabel="name"
            optionValue="value"
            @change="changeDuration"
          />
        </div>
      </div>
    </div>
    <div>
      <Button
        @click="options(!isOption)"
        :label="`${isOption ? 'Hide' : 'Display'} options`"
        class="p-button-link"
      />
    </div>
    <div v-show="isOption">
      <div class="form-input p-mt-3">
        <p>{{ i18n.$t('Select Agenda') }}</p>
        <MultiSelect
          name="agenda"
          v-model="items.agenda_id"
          :options="agendas"
          optionLabel="name"
          optionValue="_id"
        />
      </div>
      <div class="flex p-mt-3">
        <p class="w-1/5">{{ i18n.$t('Description') }}</p>
        <Editor v-model="items.description" editorStyle="height: 250px">
        </Editor>
      </div>
      <div class="flex form-input p-mt-3">
        <label>{{ i18n.$t('Color') }}</label>
        <ColorPicker v-model="items.color" />
      </div>
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { computed, watch, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import ColorPicker from 'primevue/colorpicker';
import axios from '@/plugins/axios';
import TreeSelect from 'primevue/treeselect';
import {
  FETCH_AGENCY,
  GET_AGENCY_MODEL,
  FETCH_EMPLOYEES,
  GET_AGENDA,
  GET_EMPLOYEES,
  FETCH_CATEGORY_SERVICE,
  GET_CATEGORY_SERVICE,
  FETCH_AGENDA,
  GET_CATEGORY_TREE,
  GET_USERDATA,
  FETCH_ALL_CATEGORIES,
  FETCH_CATEGORY_PARENTS,
  FETCH_TREE_CHILD_CATEGORIES,
  GET_TREE_CHILD_CATEGORIES
} from '@/store/types';
import { hoursList, minutesList } from '@/store/constant';
import category from '../../../store/category';
export default {
  components: {
    ColorPicker,
    TreeSelect
  },
  props: {
    items: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);
    const agendas = computed(() => store.getters[GET_AGENDA]);
    const isOption = ref(false);
    const showOther = ref(false);
    const categoryCode = ref(null);
    const filterCategory = ref('');
    const motifs = ref([]);
    const motifs_selected = ref([]);

    const selected_category = ref();
    const loadingCategories = ref(false);
    const lastlevelCategories = ref([]);

    let defaultCategory = {};
    if (props.items.category) {
      if (props.items.category.tree_id) {
        defaultCategory[props.items.category.tree_id] = true;
      }
      lastlevelCategories.value = [props.items.category];
      selected_category.value = props.items.category;
    }
    const category_id = ref(defaultCategory);

    const employees = computed(() => {
      let items = store.getters[GET_EMPLOYEES];
      const withoutDirector = items.filter((el) => el.role !== 'director');
      return withoutDirector;
    });

    const categories = computed(() => store.getters[GET_CATEGORY_SERVICE]);
    let allCategories = {};
    function findAll(nodes) {
      nodes.forEach((el) => {
        allCategories[el.key] = el;
        if (el.children && el.children.length) {
          findAll(el.children);
        }
      });
    }
    function filterCategories(items) {
      if (filterCategory.value == '') {
        return items;
      }
      if (items.length == 0) {
        return [];
      }
      let res = [];
      items.forEach((item) => {
        if (item.children.length > 0) {
          const children = filterCategories(item.children);
          if (children.length > 0) {
            item.children = children;
            res.push(item);
          }
        } else if (item.selectable) {
          const filters = filterCategory.value.split(' ');
          let match = true;
          filters.forEach((f) => {
            if (f == '') {
              return;
            }
            if (!item.label.toLowerCase().includes(f.toLowerCase())) {
              match = false;
            }
          });
          if (match) {
            res.push(item);
          }
        }
      });
      return res;
    }
    const childrenCategories = computed(() => {
      const tree = store.getters[GET_TREE_CHILD_CATEGORIES];
      // if (tree && tree.length && Object.keys(allCategories).length == 0)
      //   findAll(tree);

      if (filterCategory.value != '') {
        let newTree = JSON.parse(JSON.stringify(tree));
        return filterCategories(newTree);
      } else {
        return tree;
      }
    });
    const agency = computed(() => {
      const agency = store.getters[GET_AGENCY_MODEL];
      //console.log('agency',agency);
      store.dispatch(FETCH_TREE_CHILD_CATEGORIES, agency.category);
      return agency;
    });

    store.dispatch(FETCH_AGENDA, userData.value.id);

    store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);

    store.dispatch(FETCH_CATEGORY_SERVICE, userData.value.organisation_id);

    store.dispatch(FETCH_AGENCY);

    console.log("currency ageny ", agency.value.currency);
    
    props.items.currency = 'EUR';

    const providerTypes = [
      { name: i18n.$t('Human'), value: 'Human' },
      { name: i18n.$t('Material'), value: 'Material' },
      { name: i18n.$t('Space'), value: 'Space' }
    ];

    props.items.type = 'Human';

    const bookingUserUnits = [
      { name: '1', value: 1 },
      { name: '2', value: 2 },
      { name: '3', value: 3 }
    ];

    const hours = ref(0);
    const minutes = ref(15);

    if (props.items.book_duration) {
      const value = props.items.book_duration;
      hours.value = Math.trunc(value / 60);
      minutes.value = value - hours.value * 60;
    }

    function changeDuration() {
      if (hours.value > 0) {
        props.items.book_duration = hours.value * 60 + minutes.value;
      } else if (hours.value == 0) {
        props.items.book_duration = minutes.value;
      }
    }

    function onChangeTreeCategory(event) {
      const id = Object.keys(event)[0];

      loadingCategories.value = true;
      //console.log('cat', event, allCategories[id]);
      axios.get(`category/children?id=${id}`).then(function (res) {
        lastlevelCategories.value = res.data.data.items;
        loadingCategories.value = false;
      });
    }
    function onChangeCategory(event) {
      props.items.name = event.value.name;
      props.items.category_id = event.value.id;
      props.items.category = event.value;
      props.items.category.tree_id = Object.keys(category_id.value)[0];
      categoryCode.value = event.value.code;
    }

    async function options(visibility) {
      isOption.value = visibility;
    }

    async function filterMotifs(event) {
      axios
        .get(`data/motifs?q=${event.query}&code=${categoryCode.value}`)
        .then(function (res) {
          motifs.value = res.data.data.items;
          if (
            event.query == '' &&
            props.items.motifs_ids.length > 0 &&
            motifs_selected.value.length == 0
          ) {
            res.data.data.items.forEach((mot) => {
              if (props.items.motifs_ids.includes(mot.id))
                motifs_selected.value.push(mot);
            });
          }
        });
    }
    //filterMotifs({ query: '' });
    function changeMotiffs(e) {
      let selected = [];
      for (const motiff of motifs_selected.value) {
        selected.push(motiff.id);
      }
      props.items.motifs_ids = selected;
    }

    function doFilterCategory(e) {
      filterCategory.value = e.target.value.toLowerCase();
    }
    return {
      i18n,
      providerTypes,
      bookingUserUnits,
      agendas,
      employees,
      categories,
      hours,
      minutes,
      hoursList,
      minutesList,
      changeDuration,
      childrenCategories,
      isOption,
      showOther,
      options,
      agency,
      onChangeTreeCategory,
      onChangeCategory,
      filterMotifs,
      changeMotiffs,
      motifs,
      motifs_selected,
      category_id,
      filterCategory,
      doFilterCategory,
      categoryCode,
      lastlevelCategories,
      loadingCategories,
      selected_category
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.duration {
  display: flex;
  align-items: center;
  .p-dropdown {
    width: 120px;
  }
  label {
    font-size: 12px;
    margin-bottom: 0.15rem;
    display: block;
  }
}
</style>
<style>
.p-colorpicker {
  max-width: 28px;
}
</style>
